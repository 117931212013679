<template>
<Header/> 
            <router-view></router-view>
   



</template>

<script>
import Header from './components/Hello.vue'

export default {
  name: 'Default',
  
 components: {
    Header
  }
  };
  
</script>

<style>
</style>
