<template>
  <div style="margin-top: 230px">
      <div class=" text-center">
          <div class="container">
                <h1>404 - Page Not Found</h1>
                <p>This page no longer exists or was moved to another location.</p>
          </div>
      </div>
    
  </div>
</template>