//import vue router
import { createRouter, createWebHistory } from "vue-router";
import PageNotFound from "@/views/404.vue";
//define a routes
const routes = [
  {
    path: "/",
    name: "login.index",
    meta: {
      layout: "login",
    },
    component: () => import("@/views/Login.vue"),
  },
  {
    path: "/dashboard",
    name: "dashboard.index",
    meta: {
      layout: "app",
    },
    component: () => import("@/views/dashboard/index.vue"),
  },
  {
    path: "/order",
    name: "order.index",
    meta: {
      layout: "app",
    },
    component: () => import("@/views/order/Index.vue"),
  },
  {
    path: "/profile",
    name: "profile.index",
    meta: {
      layout: "app",
    },
    component: () => import("@/views/profile/Index.vue"),
  },
  {
    path: "/penjualan",
    name: "penjualan.index",
    meta: {
      layout: "app",
    },
    component: () => import("@/views/penjualan/Index.vue"),
  },
  {
    path: "/lupapw",
    name: "lupapw.index",
    meta: {
      layout: "error",
    },
    component: () => import("@/views/lupapw/index.vue"),
  },

  {
    path: "/hutang",
    name: "hutang.index",
    meta: {
      layout: "app",
    },
    component: () => import("@/views/hutang/index.vue"),
  },
  {
    path: "/hutang/print",
    name: "hutang.print",
    meta: {
      layout: "error",
    },
    component: () => import("@/views/print/hutang.vue"),
  },
  // {
  //     path: '/supplier',
  //     name: 'supplier.index',
  //     meta: {
  //         layout: 'app',
  //     },
  //     component: () => import(  '@/views/supplier/Index.vue')
  // },
  {
    path: "/cabang",
    name: "cabang.index",
    meta: {
      layout: "app",
    },
    component: () => import("@/views/cabang/Index.vue"),
  },
  {
    path: "/kategori",
    name: "kategori.index",
    meta: {
      layout: "app",
    },
    component: () => import("@/views/kategori/Index.vue"),
  },
  {
    path: "/barang",
    name: "barang.index",
    meta: {
      layout: "app",
    },
    component: () => import("@/views/barang/Index.vue"),
  },
  {
    path: "/barang/riwayat_stok",
    name: "barang.riwayat_stok",
    meta: {
      layout: "app",
    },
    component: () => import("@/views/barang/StockHistory.vue"),
  },
  {
    path: "/karyawan",
    name: "karyawan.index",
    meta: {
      layout: "app",
    },
    component: () => import("@/views/karyawan/Index.vue"),
  },
  {
    path: "/print",
    name: "print.index",
    props: true,
    meta: {
      layout: "error",
    },
    component: () => import("@/views/print/Index.vue"),
  },
  {
    path: "/print-barcode",
    name: "print.barcode",
    meta: {
      layout: "error",
    },
    component: () => import("@/views/print/print.vue"),
  },

  {
    path: "/:catchAll(.*)*",
    meta: {
      layout: "error",
    },
    component: PageNotFound,
  },
];

//create router
const router = createRouter({
  history: createWebHistory(),
  routes, // config routes
  linkActiveClass: "active",
});

export default router;
